<template lang="">
   <router-link :to="{name: 'product', params: {uuid: product.uuid}}" class="product-card">
     <div class="product-card__wrapper">
         <div class="product-card__image">
            <div class="product-card__header">
               <div class="product-card__banners">
                  <span 
                     v-for="(banner, index) in this.banners"
                     :key="index"
                     :class="{
                     new: banner == 'новинка' || 'new',
                     discount: banner == 'скидка',
                     grill: banner == 'гриль',
                     popular: banner == 'популярно',
                  }"
                  >
                     {{banner}}
                  </span>
               </div>
               <button 
               class="product-card__like" 
               :class="{checked: product.liked}"
               @click.prevent="this.$emit('likeProduct')"
               >
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <g id="like">
                        <path id="Vector" d="M21.9998 8.862C22.0032 10.4007 21.4104 11.881 20.3458 12.992C17.9048 15.523 15.5367 18.162 13.0057 20.6C12.4247 21.15 11.5038 21.13 10.9488 20.555L3.65375 12.993C1.44875 10.707 1.44875 7.017 3.65375 4.732C4.17489 4.185 4.80169 3.74952 5.49614 3.45197C6.19059 3.15442 6.93824 3.001 7.69375 3.001C8.44926 3.001 9.19691 3.15442 9.89136 3.45197C10.5858 3.74952 11.2126 4.185 11.7338 4.732L11.9998 5.006L12.2648 4.732C12.7869 4.18629 13.4139 3.75161 14.1081 3.454C14.8023 3.15639 15.5494 3.00197 16.3048 3C17.8248 3 19.2777 3.624 20.3447 4.732C21.4097 5.84284 22.0029 7.32311 21.9998 8.862Z" stroke="#E0E0E0" stroke-width="2" stroke-linejoin="round"/>
                     </g>
                  </svg>
               </button>
            </div>
            <img v-if="product.get_image" :src="product.get_image" @error="replaceByDefault" alt="">
            <img v-else src="@/assets/img/icons/non-image-catalog.png" alt="">
         </div>
         <div class="product-card__info">
            <div class="product-card__main">
               <div class="product-card__article" :class="{hidden: !product.artikul}">Арт. {{product.artikul}}</div>
               <div class="product-card__name"><span>{{product.name}}</span><span v-if="product.country">, {{product.country}}</span></div>
            </div>
            <div class="product-card__footer">
               <div class="product-card__weight">{{product.ves}}</div>
               <div 
                  class="product-card__available available" 
               >
                  В наличии
               </div>
               <div 
                  class="product-card__available not-available" 
                  style="display: none"
               >
                  Под заказ
               </div>
            </div>
         </div>
     </div>
   </router-link>
</template>
<script>
export default {
   name: "product-card",
   data() {
      return {
         product: this.productInfo,
      }
   },
   props: {
      productInfo: {
         type: Object,
         default() {
            return {}
         },
      }
   },
   watch: {
      productInfo() {
         this.product = this.productInfo
      }
   },
   computed: {
      banners() {
         if (this.product.tags) {
            return this.product.tags.split(", ")
         } else {
            return this.product.banners
         }
      },
   },
   methods: {
      replaceByDefault() {
         // e.target.src = "https://test.moreodor.com/image/product/non-image-product.svg"
      },
   }
}
</script>
<style lang="">
   
</style>