<template>
   <div class="root">
      <router-view />
      <!-- <timeout-vue /> -->
   </div>
</template>

<script>
// import timeoutVue from "@/components/Technical/timeout-vue.vue";
import "@/assets/js/app";
import "swiper/css";
import "aos/dist/aos.css";
// import vHeader from "@/components/Header/v-header"
export default {
   name: "app",
   components: {
      // timeoutVue,
      // vHeader
   },
};
</script>

<style lang="scss">
@import "@/assets/scss/style";
</style>
