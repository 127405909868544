<template lang="">
   <footer class="footer">
      <div class="footer__wrapper">
         <div class="container footer__container">
            <div class="footer__row">
               <div class="footer__graphics">
                  <router-link to="/" class="footer__logo">
                     <img src="@/assets/img/logo-adapt.svg" alt="" />
                  </router-link>
                  <div class="footer__social">
                     <a
                        :href="this.youtube"
                        class="footer__youtube footer__icon"
                        target="_blank"
                     >
                        <svg
                           xmlns="http://www.w3.org/2000/svg"
                           width="32"
                           height="32"
                           viewBox="0 0 32 32"
                           fill="none"
                        >
                           <path
                              d="M16.3255 5.33398C17.0377 5.3379 18.8194 5.35513 20.7125 5.43096L21.3837 5.46022C23.2897 5.55046 25.1941 5.70462 26.1386 5.96798C27.3985 6.32192 28.3887 7.35465 28.7234 8.66374C29.2563 10.7425 29.323 14.7999 29.3313 15.7817L29.3325 15.9852V15.9994C29.3325 15.9994 29.3325 16.0044 29.3325 16.0139L29.3313 16.2173C29.323 17.1992 29.2563 21.2565 28.7234 23.3353C28.3841 24.6492 27.3938 25.682 26.1386 26.031C25.1941 26.2944 23.2897 26.4485 21.3837 26.5388L20.7125 26.568C18.8194 26.6439 17.0377 26.6611 16.3255 26.6651L16.013 26.6661H15.9991C15.9991 26.6661 15.9945 26.6661 15.9853 26.6661L15.673 26.6651C14.1658 26.6568 7.86395 26.5886 5.85967 26.031C4.59979 25.677 3.60955 24.6444 3.27482 23.3353C2.74193 21.2565 2.67531 17.1992 2.66699 16.2173V15.7817C2.67531 14.7999 2.74193 10.7425 3.27482 8.66374C3.61419 7.3498 4.60443 6.31708 5.85967 5.96798C7.86395 5.41029 14.1658 5.34228 15.673 5.33398H16.3255ZM13.3325 11.3328V20.6661L21.3325 15.9994L13.3325 11.3328Z"
                              fill="#6D6D6D"
                           />
                        </svg>
                     </a>
                     <a
                        :href="this.vk"
                        class="footer__vk footer__icon"
                        target="_blank"
                     >
                        <!-- <img src="@/assets/img/vk1.svg" alt=""> -->
                        <svg
                           width="32"
                           height="32"
                           viewBox="0 0 32 32"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              d="M28.6713 24.508H25.6293C24.4784 24.508 24.1323 23.576 22.0681 21.5119C20.2667 19.7751 19.5056 19.5581 19.0491 19.5581C18.4193 19.5581 18.2463 19.7312 18.2463 20.6007V23.3361C18.2463 24.0764 18.0065 24.51 16.0737 24.51C14.198 24.384 12.379 23.8141 10.7666 22.8473C9.15419 21.8805 7.79456 20.5445 6.79967 18.9493C4.43771 16.0095 2.79427 12.5591 2 8.87256C2 8.41595 2.17305 8.00312 3.04249 8.00312H6.08243C6.86429 8.00312 7.14577 8.35132 7.45227 9.15404C8.92844 13.4992 11.4471 17.2772 12.4688 17.2772C12.8607 17.2772 13.0317 17.1041 13.0317 16.1263V11.6519C12.9025 9.61066 11.8183 9.4376 11.8183 8.69951C11.8322 8.50483 11.9214 8.32324 12.067 8.19323C12.2125 8.06323 12.403 7.99504 12.598 8.00312H17.3768C18.0295 8.00312 18.2463 8.32839 18.2463 9.11026V15.1484C18.2463 15.8011 18.5277 16.0179 18.7237 16.0179C19.1157 16.0179 19.418 15.8011 20.1353 15.0837C21.6759 13.2051 22.9345 11.112 23.8716 8.87048C23.9675 8.60123 24.1487 8.37072 24.3877 8.21411C24.6268 8.05751 24.9105 7.9834 25.1956 8.00312H28.2376C29.1488 8.00312 29.3427 8.45974 29.1488 9.11026C28.0427 11.5881 26.674 13.9403 25.0663 16.1263C24.7389 16.6267 24.6076 16.8873 25.0663 17.4732C25.3687 17.9297 26.4341 18.8201 27.1513 19.6665C28.1936 20.7063 29.0592 21.9092 29.7137 23.2277C29.9744 24.0743 29.5387 24.508 28.6713 24.508Z"
                              fill="#6D6D6D"
                           />
                        </svg>
                     </a>
                     <a
                        :href="tg"
                        class="footer__tg footer__icon"
                        target="_blank"
                     >
                        <!-- <img src="@/assets/img/vk1.svg" alt=""> -->
                        <svg
                           width="27"
                           height="24"
                           viewBox="0 0 27 24"
                           fill="none"
                           xmlns="http://www.w3.org/2000/svg"
                        >
                           <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M22.6437 22.3868L22.6447 22.3844L22.6679 22.3264L26.6663 2.16773V2.10225C26.6663 1.59998 26.4807 1.1615 26.0768 0.898489C25.7233 0.668276 25.3171 0.651889 25.0321 0.673516C24.7307 0.696382 24.4465 0.771609 24.2499 0.834222C24.1487 0.866435 24.064 0.897462 24.0033 0.920942C23.9729 0.932729 23.9483 0.942729 23.9304 0.950169L23.9128 0.957582L1.6197 9.70284L1.61357 9.70497C1.60153 9.70937 1.58561 9.71537 1.56637 9.72284C1.52804 9.73777 1.47585 9.7591 1.41454 9.78697C1.29417 9.8415 1.12785 9.92564 0.959184 10.0411C0.672717 10.2374 0.127223 10.7018 0.21933 11.438C0.295623 12.0479 0.716037 12.4352 0.999463 12.6358C1.15138 12.7434 1.29674 12.8208 1.40318 12.8715C1.4571 12.8972 1.50292 12.9168 1.53704 12.9307C1.55414 12.9376 1.56844 12.9432 1.5795 12.9475L1.59368 12.9527L1.60273 12.9562L5.50305 14.2692C5.48989 14.5139 5.51416 14.7634 5.57905 15.0096L7.53277 22.4208C7.76441 23.2995 8.55938 23.9116 9.46814 23.911C10.2827 23.9104 11.0052 23.4176 11.3121 22.6824L14.3623 19.421L19.6009 23.4372L19.6755 23.4698C20.1513 23.6775 20.5961 23.7432 21.0027 23.6878C21.4087 23.6323 21.7312 23.462 21.9736 23.2682C22.2121 23.0774 22.3755 22.862 22.4783 22.6999C22.5305 22.6175 22.5695 22.5451 22.5964 22.4906C22.61 22.4632 22.6207 22.4402 22.6287 22.422L22.6388 22.3988L22.6424 22.3903L22.6437 22.3868ZM7.51292 14.4998C7.46852 14.3314 7.53822 14.1535 7.68528 14.0602L20.9147 5.65893C20.9147 5.65893 21.6928 5.18653 21.6649 5.65893C21.6649 5.65893 21.8039 5.74234 21.3868 6.13132C20.9913 6.50076 11.958 15.2222 11.0439 16.1047C10.9911 16.1556 10.9596 16.214 10.941 16.285L9.46664 21.911L7.51292 14.4998Z"
                              fill="#6D6D6D"
                           />
                        </svg>
                     </a>
                  </div>
               </div>
               <div class="footer__main">
                  <form action="" class="footer__form footer-form">
                     <div class="footer-form__title">
                        Подпишитесь на нашу рассылку, чтобы узнавать о скидках и
                        закрытых распродажах первыми!
                     </div>
                     <div class="footer-form__input">
                        <input
                           type="text"
                           placeholder="Введите e-mail"
                           v-model="footerEmail"
                        />
                        <button>
                           <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="34"
                              height="24"
                              viewBox="0 0 34 24"
                              fill="none"
                           >
                              <path
                                 d="M22 13H0V11H22V4L30 12L22 20V13Z"
                                 fill="#797979"
                              />
                           </svg>
                        </button>
                     </div>
                  </form>
                  <nav class="footer__nav footer-nav">
                     <ul class="footer-nav__list">
                        <li v-for="(link, index) in mainNavList" :key="index">
                           <router-link
                              :to="'/' + link.to"
                              class="footer-nav__link"
                           >
                              {{ link.name }}
                           </router-link>
                        </li>
                     </ul>
                     <ul class="footer-nav__list">
                        <li
                           v-for="(link, index) in secondaryNavList"
                           :key="index"
                        >
                           <router-link
                              :to="'/' + link.to"
                              class="footer-nav__link"
                              :target="link.target"
                           >
                              {{ link.name }}
                           </router-link>
                        </li>
                     </ul>
                     <ul class="footer-nav__interaction">
                        <li>
                           <a
                              class="footer-nav__phone"
                              :href="'tel:' + SCHEDULE.phone"
                              >{{ SCHEDULE.phone }}</a
                           >
                        </li>
                        <li>
                           <span class="footer-nav__schedule">
                              {{ SCHEDULE.timeWork }}
                           </span>
                        </li>
                     </ul>
                  </nav>
               </div>
               <div class="footer__social">
                  <a
                     :href="this.youtube"
                     class="footer__youtube footer__icon"
                     target="_blank"
                  >
                     <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                     >
                        <path
                           d="M16.3255 5.33398C17.0377 5.3379 18.8194 5.35513 20.7125 5.43096L21.3837 5.46022C23.2897 5.55046 25.1941 5.70462 26.1386 5.96798C27.3985 6.32192 28.3887 7.35465 28.7234 8.66374C29.2563 10.7425 29.323 14.7999 29.3313 15.7817L29.3325 15.9852V15.9994C29.3325 15.9994 29.3325 16.0044 29.3325 16.0139L29.3313 16.2173C29.323 17.1992 29.2563 21.2565 28.7234 23.3353C28.3841 24.6492 27.3938 25.682 26.1386 26.031C25.1941 26.2944 23.2897 26.4485 21.3837 26.5388L20.7125 26.568C18.8194 26.6439 17.0377 26.6611 16.3255 26.6651L16.013 26.6661H15.9991C15.9991 26.6661 15.9945 26.6661 15.9853 26.6661L15.673 26.6651C14.1658 26.6568 7.86395 26.5886 5.85967 26.031C4.59979 25.677 3.60955 24.6444 3.27482 23.3353C2.74193 21.2565 2.67531 17.1992 2.66699 16.2173V15.7817C2.67531 14.7999 2.74193 10.7425 3.27482 8.66374C3.61419 7.3498 4.60443 6.31708 5.85967 5.96798C7.86395 5.41029 14.1658 5.34228 15.673 5.33398H16.3255ZM13.3325 11.3328V20.6661L21.3325 15.9994L13.3325 11.3328Z"
                           fill="#6D6D6D"
                        />
                     </svg>
                  </a>
                  <a
                     :href="this.vk"
                     class="footer__vk footer__icon"
                     target="_blank"
                  >
                     <!-- <img src="@/assets/img/vk1.svg" alt=""> -->
                     <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           d="M28.6713 24.508H25.6293C24.4784 24.508 24.1323 23.576 22.0681 21.5119C20.2667 19.7751 19.5056 19.5581 19.0491 19.5581C18.4193 19.5581 18.2463 19.7312 18.2463 20.6007V23.3361C18.2463 24.0764 18.0065 24.51 16.0737 24.51C14.198 24.384 12.379 23.8141 10.7666 22.8473C9.15419 21.8805 7.79456 20.5445 6.79967 18.9493C4.43771 16.0095 2.79427 12.5591 2 8.87256C2 8.41595 2.17305 8.00312 3.04249 8.00312H6.08243C6.86429 8.00312 7.14577 8.35132 7.45227 9.15404C8.92844 13.4992 11.4471 17.2772 12.4688 17.2772C12.8607 17.2772 13.0317 17.1041 13.0317 16.1263V11.6519C12.9025 9.61066 11.8183 9.4376 11.8183 8.69951C11.8322 8.50483 11.9214 8.32324 12.067 8.19323C12.2125 8.06323 12.403 7.99504 12.598 8.00312H17.3768C18.0295 8.00312 18.2463 8.32839 18.2463 9.11026V15.1484C18.2463 15.8011 18.5277 16.0179 18.7237 16.0179C19.1157 16.0179 19.418 15.8011 20.1353 15.0837C21.6759 13.2051 22.9345 11.112 23.8716 8.87048C23.9675 8.60123 24.1487 8.37072 24.3877 8.21411C24.6268 8.05751 24.9105 7.9834 25.1956 8.00312H28.2376C29.1488 8.00312 29.3427 8.45974 29.1488 9.11026C28.0427 11.5881 26.674 13.9403 25.0663 16.1263C24.7389 16.6267 24.6076 16.8873 25.0663 17.4732C25.3687 17.9297 26.4341 18.8201 27.1513 19.6665C28.1936 20.7063 29.0592 21.9092 29.7137 23.2277C29.9744 24.0743 29.5387 24.508 28.6713 24.508Z"
                           fill="#6D6D6D"
                        />
                     </svg>
                  </a>
                  <a :href="tg" class="footer__tg footer__icon" target="_blank">
                     <!-- <img src="@/assets/img/vk1.svg" alt=""> -->
                     <svg
                        width="27"
                        height="24"
                        viewBox="0 0 27 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M22.6437 22.3868L22.6447 22.3844L22.6679 22.3264L26.6663 2.16773V2.10225C26.6663 1.59998 26.4807 1.1615 26.0768 0.898489C25.7233 0.668276 25.3171 0.651889 25.0321 0.673516C24.7307 0.696382 24.4465 0.771609 24.2499 0.834222C24.1487 0.866435 24.064 0.897462 24.0033 0.920942C23.9729 0.932729 23.9483 0.942729 23.9304 0.950169L23.9128 0.957582L1.6197 9.70284L1.61357 9.70497C1.60153 9.70937 1.58561 9.71537 1.56637 9.72284C1.52804 9.73777 1.47585 9.7591 1.41454 9.78697C1.29417 9.8415 1.12785 9.92564 0.959184 10.0411C0.672717 10.2374 0.127223 10.7018 0.21933 11.438C0.295623 12.0479 0.716037 12.4352 0.999463 12.6358C1.15138 12.7434 1.29674 12.8208 1.40318 12.8715C1.4571 12.8972 1.50292 12.9168 1.53704 12.9307C1.55414 12.9376 1.56844 12.9432 1.5795 12.9475L1.59368 12.9527L1.60273 12.9562L5.50305 14.2692C5.48989 14.5139 5.51416 14.7634 5.57905 15.0096L7.53277 22.4208C7.76441 23.2995 8.55938 23.9116 9.46814 23.911C10.2827 23.9104 11.0052 23.4176 11.3121 22.6824L14.3623 19.421L19.6009 23.4372L19.6755 23.4698C20.1513 23.6775 20.5961 23.7432 21.0027 23.6878C21.4087 23.6323 21.7312 23.462 21.9736 23.2682C22.2121 23.0774 22.3755 22.862 22.4783 22.6999C22.5305 22.6175 22.5695 22.5451 22.5964 22.4906C22.61 22.4632 22.6207 22.4402 22.6287 22.422L22.6388 22.3988L22.6424 22.3903L22.6437 22.3868ZM7.51292 14.4998C7.46852 14.3314 7.53822 14.1535 7.68528 14.0602L20.9147 5.65893C20.9147 5.65893 21.6928 5.18653 21.6649 5.65893C21.6649 5.65893 21.8039 5.74234 21.3868 6.13132C20.9913 6.50076 11.958 15.2222 11.0439 16.1047C10.9911 16.1556 10.9596 16.214 10.941 16.285L9.46664 21.911L7.51292 14.4998Z"
                           fill="#6D6D6D"
                        />
                     </svg>
                  </a>
               </div>
            </div>
         </div>
      </div>
      <div class="footer__copy footer-copy">
         <div class="container footer__container">
            <ul class="footer-copy__list">
               <span>2023. Мореодор. Все права защищены</span>
               <span
                  >Любое копирование информации запрещено без письменного
                  разрешения</span
               >
               <span
                  >Сайт носит сугубо информационный характер и не является
                  публичной офертой, определяемой Статьей 437 (2) ГК РФ</span
               >
               <span class="made-by"
                  >Made by
                  <a target="_blank" href="https://oktta.ru/">OKTTA</a></span
               >
            </ul>
         </div>
      </div>
   </footer>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
   name: "v-footer",
   data() {
      return {
         footerEmail: "",
         mainNavList: [
            {
               name: "Каталог",
               to: "catalog",
            },
            {
               name: "О компании",
               to: "about/ru",
            },
            {
               name: "Новости",
               to: "news",
            },
            {
               name: "Контакты",
               to: "contacts",
            },
         ],
         secondaryNavList: [
            {
               name: "Документы",
               target: "",
               to: "documents",
            },
            {
               name: "Политика конфиденциальности",
               target: "_blank",
               to: "policy",
            },
            {
               name: "Пользовательское соглашение",
               target: "_blank",
               to: "personal",
            },
         ],
         vk: "https://vk.com/moreodor",
         youtube: "https://www.youtube.com/@moreodor/featured",
         tg: "https://t.me/moreodorru",
         phone: "",
         timeWork: "",
      };
   },
   methods: {
      ...mapActions(["GET_SCHEDULE"]),
   },
   computed: {
      ...mapGetters(["SCHEDULE"]),
   },
   created() {},
};
</script>
<style lang="scss">
.footer {
   &__vk {
      svg {
         width: 100%;
         height: 100%;
      }
   }
}

.footer-copy {
   &__list {
      gap: 20px;
   }
}
</style>
