<template lang="">
   <section class="product-slider" v-if="productsInfo.length">
      <div class="container">
         <div class="product-slider__header">
            <h2 class="product-slider__title title">
               <slot></slot>
            </h2>
            <more-btn to="catalog"></more-btn>
         </div>
      </div>
      <div class="product-slider__content">
            <swiper
               :modules="modules"
               :navigation="{
                  prevEl: prev,
                  nextEl: next,
               }"
               :breakpoints="swiperOptions.breakpoints"
               class="product-slider__slider"
            >
               <swiper-slide 
                  class="product-slider__item"
                  v-for="(product, index) in this.productsInfo"
                  :key="index"
               >
                  <product-card 
                     :productInfo="product"
                     @likeProduct="this.$emit('likeProduct', index)"
                  />
               </swiper-slide>
            </swiper>
            <div ref="prev" class="product-slider__btn-prev" v-if="productsInfo.length > 5"></div>
            <div ref="next" class="product-slider__btn-next" v-if="productsInfo.length > 5"></div>
      </div>
   </section>
</template>
<script>
import { ref } from 'vue';
import productCard from "../Product/product-card"
import moreBtn from "../Buttons/more-btn"
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper/modules';
export default {
   name: "product-slider",
   components: {
      Swiper,
      SwiperSlide,
      moreBtn,
      productCard
   },
   props: {
      productsInfo: {
         type: Array,
         default() {
            return []
         }
      },
   },
   data() {
      return {
         swiperOptions: {
            breakpoints: {
               320: {
                  slidesPerView: 1.8,
                  spaceBetween: 10
               },
               375: {
                  slidesPerView: 2,
                  spaceBetween: 10
               },
               450: {
                  slidesPerView: 2.3,
                  spaceBetween: 10
               },
               470: {
                  slidesPerView: 2.7,
                  spaceBetween: 10
               },
               550: {
                  slidesPerView: 3,
                  spaceBetween: 10
               },
               600: {
                  slidesPerView: 3,
                  spaceBetween: 10
               },
               651: {
                  slidesPerView: 2.5,
                  spaceBetween: 10
               },
               750: {
                  slidesPerView: 2.8,
                  spaceBetween: 15,
               },
               850: {
                  slidesPerView: 3.2,
                  spaceBetween: 15,
               },
               992: {
                  slidesPerView: 3.5,
                  spaceBetween: 15,
               },
               1050: {
                  slidesPerView: 4,
                  spaceBetween: 15,
               },
               1150: {
                  slidesPerView: 4,
                  spaceBetween: 24,
               },
               1350: {
                  slidesPerView: 5,
                  spaceBetween: 24,
               },
               // 1600: {
               //    slidesPerView: 6,
               //    spaceBetween: 24,
               // }
            }
         },
      }
   },
   computed: {
      products() {
         let products = this.productsInfo;
         if (products.length > 5) {
            return products.splice(8, products.length)
         } else {
            return products
         }
      }
   },
   methods: {
   },
   mounted() {
   },
   setup() {
      const prev = ref(null);
      const next = ref(null);
      return {
         modules: [
            Navigation,
         ],
         prev, next,
      };
   },
}
</script>
<style lang="scss">
.swiper-slide {
   height: unset;
}
</style>