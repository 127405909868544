import { createStore } from 'vuex'
import axios from "axios"
export default createStore({
   state: {
      catalog: [],
      popularProducts: [],
      schedule: {},
      recentlyViewed: [],
   },
   getters: {
      CATALOG(state) {
         return state.catalog;
      },
      POPULAR_PRODUCTS(state) {
         return state.popularProducts
      },
      SCHEDULE(state) {
         return state.schedule
      },
      RECENTLY_VIEWED(state) {
         return state.recentlyViewed
      }
   },
   mutations: {
      SET_CATALOG_TO_STATE: (state, data) => {
         state.catalog = data;
      },
      SET_POPULAR: (state, data) => {
         state.popularProducts = data
      },
      SET_SCHEDULE: (state, data) => {
         state.schedule = data
      },
      ADD_TO_RECENTLY: (state, product) => {
         if (state.recentlyViewed.length >= 5) {
            state.recentlyViewed.pop()
         }
         state.recentlyViewed.forEach(item => {
            console.log(item.uuid === product.uuid);
            if (item.uuid == product.uuid) {
               return
            }
         })
         state.recentlyViewed.splice(0, 0, product)
      },
   },
   actions: {
      async GET_CATALOG_FROM_API({ commit }) {
         await axios("/api/v1/get/catalog/")
            .then(res => {
               commit("SET_CATALOG_TO_STATE", res.data);
            })

      },
      async GET_POPULAR_PRODUCTS({ commit }) {
         await axios("/api/v1/get/products/popular-list/")
            .then(res => {
               commit("SET_POPULAR", res.data);
            })
            .catch(err => {
               console.log(err);
            })
      },
      async GET_SCHEDULE({ commit }) {
         await axios("/api/v1/get/time-work/info/")
            .then(res => {
               // commit("SET_CONTACT_PHONE", res.data.phone);
               commit("SET_SCHEDULE", res.data);
            })
            .catch(err => {
               console.log(err);
            })
      }
   },
})
