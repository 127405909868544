<template lang="">
   <div class="home-hero__slider home-slider home-hero__banner">
      <swiper
         class="home-slider__swiper"
         :slides-per-view="1"
         :space-between="10"
         :pagination="{ clickable: true }"
         :autoplay="{ delay: 4000 }"
         :modules="modules"
      >
         <swiper-slide
            class="home-slider__slide home-slide"
            v-for="(slide, index) in slidesInfo"
            :key="index"
         >
            <router-link
               v-if="!slide?.link.includes('http')"
               :to="slide.link"
               class="home-slide__content"
            >
               <img :src="slide.get_image" alt="" />
               <div class="home-slide__title" v-if="slide.name">
                  {{ slide.name }}
               </div>
               <div class="home-slide__text" v-if="slide.description">
                  {{ slide.description }}
               </div>
            </router-link>
            <a
               v-else
               :href="slide.link"
               class="home-slide__content"
               target="_blank"
            >
               <img :src="slide.get_image" alt="" />
               <div class="home-slide__title" v-if="slide.name">
                  {{ slide.name }}
               </div>
               <div class="home-slide__text" v-if="slide.description">
                  {{ slide.description }}
               </div>
            </a>
         </swiper-slide>
      </swiper>
   </div>
</template>
<script>
// :style="{background: `url(${slide.get_image}) center / cover no-repeat, #075D84`}"
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper/modules";
export default {
   name: "home-hero-slider",
   components: {
      Swiper,
      SwiperSlide,
   },
   props: {
      slidesInfo: {
         type: Array,
         default() {
            return [];
         },
      },
   },
   data() {
      return {};
   },
   computed: {},
   setup() {
      return {
         modules: [Pagination, Autoplay],
      };
   },
};
</script>
<style lang=""></style>
