<template>
   <router-link :to="'/' + to" class="homeView__more-btn">
      <slot>Смотреть все</slot>
   </router-link>
</template>
<script>
export default {
   name: "more-btn",
   props: {
      to: {
         type: String,
         default: ''
      }
   }
}
</script>
<style lang="scss"></style>