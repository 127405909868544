<template>
   <header class="header lock-padding" ref="header">
      <div class="backdrop" :class="{ active: isBurgerOpen || isCatalogOpen || isSearchOpen }"
         @click="isSearchOpen = false"></div>
      <div class="header__upper upper-header">
         <div class="container">
            <div class="upper-header__row">
               <ul class="upper-header__list">
                  <li v-for="(list, index) in headerLinks" :key="index">
                     <router-link class="upper-header__link" :to="'/' + list.to">{{ list.title }}</router-link>
                  </li>
               </ul>
               <router-link to="/" class="upper-header__logo">
                  <!-- <img class="upper-header__logo_image" src="@/assets/img/logo.svg" alt="">
                  <img class="upper-header__logo_image_adapt" src="@/assets/img/logo-adapt.svg" alt=""> -->
                  <vue3-lottie class="upper-header__logo_icon" ref="logo"
                     :animationData="require('@/assets/json/data.json')" :width="83" :height="94" />
                  <svg class="upper-header__logo_text" width="121" height="12" viewBox="0 0 121 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                     <path
                        d="M0 0.188538H2.37344L5.90076 4.96351L9.42477 0.188538H11.8015V11.7037H9.3952L9.41492 3.88889L5.90076 8.44367L2.38661 3.88889L2.40633 11.7037H0"
                        fill="#262626" />
                     <path
                        d="M23.1222 0.051019C25.1702 0.051019 26.6397 0.382936 27.524 1.04019C28.4083 1.69745 28.8521 2.82464 28.8521 4.41849C28.8521 6.40997 28.3622 8.12212 27.3826 9.56151C26.2748 11.1882 24.8546 11.9999 23.1222 11.9999C21.3898 11.9999 19.95 11.1915 18.8651 9.57466C17.8724 8.10569 17.376 6.38368 17.376 4.4152C17.376 2.83121 17.8198 1.7073 18.7139 1.04347C19.6048 0.379643 21.0742 0.0477295 23.1222 0.0477295M19.8415 4.59923C19.8415 5.84144 20.1143 7.01792 20.66 8.12869C21.3076 9.47277 22.1295 10.1465 23.1222 10.1465C24.115 10.1465 24.9664 9.47935 25.6042 8.14512C26.1269 7.05737 26.3866 5.90059 26.3866 4.68138C26.3866 2.81478 25.2984 1.88148 23.1222 1.88148C20.946 1.88148 19.8415 2.78849 19.8415 4.59923Z"
                        fill="#262626" />
                     <path
                        d="M34.5786 11.7052V2.47407H36.9849V7.042C39.8745 5.72749 41.3176 4.54444 41.3176 3.49611C41.3176 3.03932 41.1204 2.67126 40.7226 2.38535C40.3282 2.09944 39.8548 1.95813 39.2926 1.95813H34.0625L34.5786 0.186829H39.4307C40.7358 0.186829 41.7976 0.489163 42.6129 1.08727C43.3919 1.66894 43.7831 2.41821 43.7831 3.34166C43.7831 5.50731 41.5149 7.39363 36.9849 8.99734V11.7019"
                        fill="#262626" />
                     <path
                        d="M57.131 1.93205H51.5919V4.962H56.5755L56.0002 6.65443H51.5919V9.93414H57.5288L56.9009 11.7054H49.1855V0.193604H56.5755"
                        fill="#262626" />
                     <path
                        d="M67.785 0.051019C69.833 0.051019 71.3024 0.382936 72.1867 1.04019C73.0743 1.69745 73.5148 2.82464 73.5148 4.41849C73.5148 6.40997 73.025 8.12212 72.0454 9.56151C70.9375 11.1882 69.5207 11.9999 67.7882 11.9999C66.0558 11.9999 64.6127 11.1915 63.5312 9.57466C62.5384 8.10569 62.042 6.38368 62.042 4.4152C62.042 2.83121 62.4891 1.7073 63.3799 1.04347C64.2708 0.379643 65.7402 0.0477295 67.7915 0.0477295M64.5075 4.59923C64.5075 5.84144 64.7836 7.01792 65.3293 8.12869C65.9802 9.47277 66.802 10.1465 67.7915 10.1465C68.781 10.1465 69.639 9.47935 70.2735 8.14512C70.7929 7.05737 71.0559 5.90059 71.0559 4.68138C71.0559 2.81478 69.9645 1.88148 67.7915 1.88148C65.6186 1.88148 64.5075 2.78849 64.5075 4.59923Z"
                        fill="#262626" />
                     <path
                        d="M78.0938 11.7037L77.6533 10.0112H79.7178C79.2707 8.72629 79.0471 7.6714 79.0471 6.83997C79.0471 4.85177 79.7736 3.25792 81.2234 2.05186C82.729 0.809648 84.8033 0.188538 87.4463 0.188538H88.8401L89.3957 1.95985H87.6961C85.5955 1.95985 84.0242 2.41664 82.9821 3.32694C82.0024 4.16494 81.5094 5.39072 81.5094 6.99442C81.5094 8.23664 81.7 9.24223 82.0846 10.0079H86.3976V2.4725H88.8434V10.0079H90.7698L90.349 11.7004"
                        fill="#262626" />
                     <path
                        d="M100.339 0.051019C102.39 0.051019 103.856 0.382936 104.741 1.04019C105.628 1.69745 106.069 2.82464 106.069 4.41849C106.069 6.40997 105.579 8.12212 104.599 9.56151C103.495 11.1882 102.071 11.9999 100.342 11.9999C98.6132 11.9999 97.1668 11.1915 96.0852 9.57466C95.0892 8.10569 94.5928 6.38368 94.5928 4.4152C94.5928 2.83121 95.0398 1.7073 95.9307 1.04347C96.8216 0.379643 98.291 0.0477295 100.342 0.0477295M97.0583 4.59923C97.0583 5.84144 97.3344 7.01792 97.8768 8.12869C98.5277 9.47277 99.3495 10.1465 100.339 10.1465C101.329 10.1465 102.187 9.47935 102.824 8.14512C103.344 7.05737 103.607 5.90059 103.607 4.68138C103.607 2.81478 102.519 1.88148 100.339 1.88148C98.1595 1.88148 97.055 2.78849 97.055 4.59923"
                        fill="#262626" />
                     <path
                        d="M111.794 11.7052V2.47407H114.197V7.042C117.09 5.72749 118.533 4.54444 118.533 3.49611C118.533 3.03932 118.333 2.67126 117.938 2.38535C117.544 2.09944 117.064 1.95813 116.508 1.95813H111.278L111.794 0.186829H116.647C117.952 0.186829 119.01 0.489163 119.829 1.08727C120.611 1.66894 120.999 2.41821 120.999 3.34166C120.999 5.50731 118.731 7.39363 114.201 8.99734V11.7019"
                        fill="#262626" />
                  </svg>

               </router-link>
               <div class="upper-header__actions actions-header">
                  <div class="actions-header__upper">
                     <a :href="'tel:' + SCHEDULE.phone" class="actions-header__phone">{{ SCHEDULE.phone }}</a>
                     <span class="actions-header__schedule">{{ SCHEDULE.timeWork }}</span>
                  </div>
                  <div class="actions-header__down">
                     <button class="actions-header__catalog_btn" :class="{ active: isCatalogOpen }" @click="openCatalog">
                        <svg class="active" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                           fill="none">
                           <path d="M15 5L5 15" stroke="white" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                           <path d="M5 5L15 15" stroke="white" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round" />
                        </svg>
                        <svg class="default" width="20" height="18" viewBox="0 0 20 18" fill="none"
                           xmlns="http://www.w3.org/2000/svg">
                           <path
                              d="M5.41699 9C6.20033 6.405 9.53366 4.5 12.5003 4.5C15.467 4.5 17.5503 6.405 18.3337 9C17.5503 11.6025 15.467 13.5 12.5003 13.5C9.53366 13.5 6.20033 11.6025 5.41699 9Z"
                              stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                           <path d="M15 9V9.375" stroke="white" stroke-width="1.8" stroke-linecap="round"
                              stroke-linejoin="round" />
                           <path
                              d="M13.3333 13.4475C12.2493 12.1701 11.6621 10.6073 11.6621 8.99999C11.6621 7.39265 12.2493 5.8299 13.3333 4.55249"
                              stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                           <path
                              d="M5.83348 8.0025C5.83348 6 4.65015 4.4775 2.27515 4.125C1.44181 5.25 1.44181 7.875 2.46681 9C1.43348 10.125 1.43348 12.75 2.27515 13.875C4.65015 13.5225 5.83348 12 5.83348 9.9975"
                              stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                           <path
                              d="M8.71699 5.44501C8.50033 4.41001 7.64199 3.18001 6.66699 2.25001H11.5003C11.8957 2.24869 12.2788 2.37394 12.5811 2.60338C12.8833 2.83283 13.0851 3.15151 13.1503 3.50251L13.342 4.55251"
                              stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                           <path
                              d="M13.342 13.4475L13.1503 14.4975C13.0851 14.8485 12.8833 15.1672 12.5811 15.3966C12.2788 15.6261 11.8957 15.7513 11.5003 15.75H7.91699C8.72437 14.9261 9.16638 13.8635 9.15866 12.765"
                              stroke="white" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                        <span>Каталог</span>
                     </button>
                     <div class="actions-header__input">
                        <input type="text" :placeholder="searchPlaceholder" v-model="headerSearch"
                           v-on:keyup.enter="goToPage">
                        <router-link class="icon" :to="{ name: 'search', query: { query: this.headerSearch } }">
                           <img src="@/assets/img/icons/header-search.svg" alt="">
                        </router-link>
                     </div>
                     <div class="actions-header__buttons">
                        <router-link to="/" class="actions-header__login" style="display: none">Войти</router-link>
                        <router-link to="/" class="actions-header__cart" style="display: none">{{ cartText }}</router-link>
                        <div class="actions-header__burger header__burger" :class="{ active: isBurgerOpen }"
                           @click="openBurger">
                           <div class="actions-header__burger_wrapper">
                              <span></span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="header__search header-search" :class="{ open: isSearchOpen }">
                     <div class="header-search__wrapper">
                        <ul class="header-search__list">
                           <li v-for="(product, index) in headerSearchItems" :key="index">
                              <search-item :product="product" />
                              <!-- <product-card-list :product="product" /> -->
                           </li>
                        </ul>
                        <div v-if="this.headerSearchItemsAll - this.headerSearchItems.length > 0"
                           class="header-search__actions">
                           <button class="header-search__more">
                              И ещё {{ this.headerSearchItemsAll - this.headerSearchItems.length }}
                           </button>
                           <router-link class="header-search__link"
                              :to="{ name: 'search', query: { query: this.headerSearch } }">
                              Ко всем результатам
                           </router-link>
                        </div>
                        <div v-if="this.headerSearchItems.length === 0" class="header-search__nothing">
                           <span>Ничего не найдено</span>
                           <span>Попробуйте ввести другой запрос</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <div class="header__down down-header"
         v-if="!($route.name == 'catalog' || $route.name == 'catalogSecond' || $route.name == 'category')">
         <div class="container down-header__container">
            <ul class="down-header__list">
               <li v-for="(item, index) in headerLangs" :key="index">
                  <router-link :to="'/about/' + item.slug" class="down-header__link"><img :src="item.get_image" alt=""> {{
                     item.lang }}</router-link>
               </li>
            </ul>
         </div>
      </div>
      <div class="header__down down-header"
         v-if="$route.name == 'catalog' || $route.name == 'catalogSecond' || $route.name == 'category'">
         <div class="container down-header__container">
            <ul class="down-header__list">
               <li v-for="(item, index) in CATALOG" :key="index">
                  <router-link :to="'/catalog/' + item.slug" class="down-header__link">
                     {{ item.name }}
                  </router-link>
               </li>
            </ul>
         </div>
      </div>
      <div class="header__catalog header-catalog" :class="{ open: isCatalogOpen }">
         <div class="container">
            <div class="header-catalog__row">
               <div class="header-catalog__section" v-for="category in CATALOG" :key="category.slug">
                  <div class="header-catalog__title">{{ category.name }}</div>
                  <ul class="header-catalog__list">
                     <li v-for="item in category.subCatalog" :key="item.id">
                        <router-link :to="'/catalog/' + category.slug + '/' + item.slug" class="header-catalog__link"
                           @click="isCatalogOpen = !isCatalogOpen">
                           <span>{{ item.name }}</span>
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M9 18L15 12L9 6" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                           </svg>
                        </router-link>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
      <div class="header__menu header-menu" :class="{ open: isBurgerOpen }">
         <div class="container">
            <ul class="header-menu__list">
               <li v-for="(link, index) in headerLinks" :key="index" :class="{ hidden: index === 1 }">
                  <router-link class="header-menu__link" :to="'/' + link.to">
                     <span>{{ link.title }}</span>
                     <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 18L15 12L9 6" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" />
                     </svg>
                  </router-link>
               </li>
            </ul>
            <div class="header-menu__footer">
               <a :href="'tel:' + SCHEDULE.phone" class="header-menu__phone">{{ SCHEDULE.phone }}</a>
               <span class="header-menu__schedule">{{ SCHEDULE.timeWork }}</span>
            </div>
         </div>
      </div>
   </header>
</template>
<script>
// locally
import axios from 'axios'
import { Vue3Lottie } from 'vue3-lottie'
import searchItem from '@/components/Product/search-item.vue'
import { mapGetters, mapActions } from 'vuex'

// import productCardList from '@/components/Product/product-card-list'
export default {
   name: "v-header",
   components: {
      Vue3Lottie,
      searchItem
      // productCardList
   },
   data() {
      return {
         isSearchOpen: false,
         isBurgerOpen: false,
         isCatalogOpen: false,
         headerSearch: "",
         headerLinks: [
            {
               title: "О компании",
               to: "about/ru"
            },
            {
               title: "Каталог",
               to: "catalog"
            },
            {
               title: "Новости",
               to: "news"
            },
            {
               title: "Документы",
               to: "documents"
            },
            {
               title: "Контакты",
               to: "contacts"
            }
         ],
         cartLength: 0,
         headerLangs: [],
         // headerCatalog: {},
         headerSearchItems: [],
         headerSearchItemsAll: 0,
         headerCatalogSub: [],
         // phone: "",
         // timeWork: "",
         pageNow: "",
      }
   },
   computed: {
      ...mapGetters([
         "CATALOG",
         "SCHEDULE"
      ]),
      scrollTopPosition() {
         return document.documentElement.scrollTop
      },
      searchPlaceholder() {
         if (window.innerWidth > 992) {
            return "Поиск по продукции"
         } else {
            return "Поиск"
         }
      },
      cartText() {
         if (window.innerWidth > 992) {
            return "Корзина"
         } else {
            return this.cartLength
         }
      }
   },
   methods: {
      ...mapActions([
         "GET_CATALOG_FROM_API",
         "GET_SCHEDULE"
      ]),
      openCatalog() {
         this.isBurgerOpen = false;
         this.isSearchOpen = false;
         this.isCatalogOpen = !this.isCatalogOpen
      },
      openBurger() {
         this.isCatalogOpen = false;
         this.isSearchOpen = false;
         this.isBurgerOpen = !this.isBurgerOpen
      },
      goToPage() {
         if (this.headerSearch.length >= 3) {
            this.$router.push({ name: 'search', query: { query: this.headerSearch } })
         }
      },
      async GetMenuLinks() {
         await axios
            .get(`/api/v1/get/menu/`)
            .then(response => {
               this.headerLangs = response.data
               // console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })
      },
      // async GetCatalogLinks() {
      //    await axios(`/api/v1/get/menu/catalog-header/`)
      //       .then(response => {
      //          this.headerCatalogSub = response.data
      //       })
      //       .catch(error => console.log(error))
      // },
      // async GetCatalogFromApi() {
      //    await axios("/api/v1/get/catalog/")
      //       .then(res => {
      //          res.data.forEach(item => {
      //             this.headerCatalog[item.slug] = {
      //                name: item.name,
      //                subCatalog: item.subCatalog,
      //             }
      //          })
      //       })
      //       .catch(error => console.log(error))
      // },
      async GetSearchItems(query) {
         await axios
            .get(`/api/v1/get/fast/search/${query}/3/`)
            .then(res => {
               this.headerSearchItems = res.data.results;
               this.headerSearchItemsAll = res.data.count
               console.log(res.data);
            })
            .catch(error => {
               console.log(error)
            })
      },
      // async getContactInfo() {
      //    await axios("/api/v1/get/time-work/info/")
      //       .then(res => {
      //          this.phone = res.data.phone
      //          this.timeWork = res.data.timeWork
      //       })
      // },
      headerScroll() {
         if (window.innerWidth > 992) {
            const scrollTopPosition = document.documentElement.scrollTop;
            if (scrollTopPosition <= 0) {
               return
            }
            let scrollDown = this.oldScrollTopPosition < scrollTopPosition;
            if (scrollDown) {
               this.$refs.header.classList.add("hidden-down")
            } else {
               this.$refs.header.classList.remove("hidden-down")
            }

            this.oldScrollTopPosition = scrollTopPosition;
         }
      },
      bodyLock() {
         const lockPaddingValue = window.innerWidth - document.querySelector('.wrapper').offsetWidth + "px";
         if (lockPaddingValue !== "0px") {
            document.querySelectorAll('.lock-padding').forEach(item => {
               item.style.paddingRight = lockPaddingValue
            })
         }
         document.querySelector('body').classList.add("lock")
      },
      bodyUnlock() {
         document.querySelectorAll('.lock-padding').forEach(item => {
            item.style.paddingRight = ""
         })
         document.querySelector('body').classList.remove("lock")
      }
   },
   watch: {
      headerSearch(value) {
         if (value.length >= 3) {
            this.GetSearchItems(value)
            this.isSearchOpen = true
            this.isBurgerOpen = false;
            this.isCatalogOpen = false;
            // this.bodyLock()
         } else {
            this.isSearchOpen = false
            // this.bodyUnlock()
         }
      },
      isCatalogOpen(value) {
         if (value) {
            // document.querySelector('body').classList.add("lock")
            this.bodyLock()
         } else {
            // document.querySelector('body').classList.remove("lock")
            this.bodyUnlock()

         }
      },
      isBurgerOpen(value) {
         if (value) {
            // document.querySelector('body').classList.add("lock")
            this.bodyLock()

         } else {
            // document.querySelector('body').classList.remove("lock")
            this.bodyUnlock()
         }
      },
      isSearchOpen(value) {
         if (value) {
            // document.querySelector('body').classList.add("lock")
            this.bodyLock()

         } else {
            // document.querySelector('body').classList.remove("lock")
            this.bodyUnlock()
         }
      },
      "$route.params"() {
         document.querySelector('body').classList.remove("lock")
         this.isSearchOpen = false
      },
   },
   mounted() {
      window.addEventListener("scroll", this.headerScroll)
   },
   beforeUnmount() {
      document.querySelector('body').classList.remove("lock")
      window.removeEventListener("scroll", this.headerScroll)
   },
   created() {
      this.GetMenuLinks()
      // this.GetCatalogFromApi()
      // this.GetCatalogLinks()
      // this.getContactInfo()
      this.GET_CATALOG_FROM_API()
      this.GET_SCHEDULE()
   },
}
</script>
<style lang="">
   
</style>