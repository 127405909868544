<template lang="">
   <router-link :to="{name: 'product', params: {uuid: product.uuid}}" class="product-item">
      <div class="product-item__main" :class="{grow: !this.banners}">
         <div class="product-item__image">
            <img v-if="product.get_image" :src="product.get_image" alt="">
            <!-- <svg v-else xmlns="http://www.w3.org/2000/svg" width="91" height="82" viewBox="0 0 91 82" fill="none">
               <path d="M24.6455 41C28.2097 29.1783 43.3763 20.5 56.8747 20.5C70.373 20.5 79.8522 29.1783 83.4163 41C79.8522 52.8558 70.373 61.5 56.8747 61.5C43.3763 61.5 28.2097 52.8558 24.6455 41Z" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M68.25 41V42.7083" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M60.6666 61.2609C55.7344 55.4416 53.0625 48.3224 53.0625 41.0001C53.0625 33.6778 55.7344 26.5586 60.6666 20.7393" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M26.542 36.4559C26.542 27.3334 21.1579 20.3976 10.3516 18.7917C6.55996 23.9167 6.55996 35.8751 11.2237 41.0001C6.52204 46.1251 6.52204 58.0834 10.3516 63.2084C21.1579 61.6026 26.542 54.6667 26.542 45.5442" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M39.6605 24.805C38.6747 20.09 34.7693 14.4867 30.333 10.25H52.3247C54.1238 10.244 55.8667 10.8146 57.242 11.8599C58.6173 12.9051 59.5353 14.3569 59.8322 15.9559L60.7043 20.7392" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
               <path d="M60.7043 61.2608L59.8322 66.0441C59.5353 67.6431 58.6173 69.0949 57.242 70.1401C55.8667 71.1854 54.1238 71.756 52.3247 71.7499H36.0205C39.6941 67.9967 41.7052 63.156 41.6701 58.1516" stroke="#DEDEDE" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> -->
            <img v-else src="@/assets/img/icons/non-image-catalog1.svg" alt="">
         </div>
         <div class="product-item__desc">
            <span class="product-item__article product-card__article" :class="{hidden: !product.artikul}">Арт. {{product.artikul}}</span>
            <span class="product-item__name product-card__name"><span>{{product.name}}</span><span v-if="product.country">, {{product.country}}</span></span>
         </div>
      </div>
      <div class="product-item__banners" v-if="this.banners">
         <span class="product-item__banner"
            v-for="(banner, index) in this.banners"
            :key="index"
            :class="{
               new: banner == 'новинка' || 'new',
               discount: banner == 'скидка',
               grill: banner == 'гриль',
               popular: banner == 'популярно',
            }"
         >
            {{banner}}
         </span>
      </div>
      <div class="product-item__weight">
         <span v-if="product.price" class="price">1000 Р</span>
         <span class="weight">{{product.ves}}</span>
      </div>
   </router-link>
</template>
<script>
export default {
   name: "search-item",
   props: {
      product: {
         type: Object,
         default() {
            return {}
         },
      }
   },
   computed: {
      banners() {
         if (this.product.tags) {
            return this.product.tags.split(", ")
         } else {
            return this.product.banners
         }
      },
   },
   mounted() {
      console.log(this.product);
   }
}
</script>
<style lang="">
   
</style>