<template>
   <div class="wrapper lock-padding">
      <v-header />
      <main class="main">
         <section class="home-hero">
            <div class="container">
               <div class="home-hero__row">
                  <div class="home-hero__content">
                     <home-hero-slider :slidesInfo="heroSliderInfo" />
                     <div class="home-hero__news hero-news home-hero__banner">
                        <div class="hero-news__row">
                           <div class="hero-news__title">Новости</div>
                           <ul class="hero-news__list">
                              <li v-for="(item, index) in heroNews" :key="index">
                                 <router-link :to="'/article/' + item.slug" class="hero-news__item">
                                    <span class="hero-news__date">
                                       {{ CorDate(item.date) }}
                                    </span>
                                    <p class="hero-news__heading">
                                       {{ item.heading }}
                                    </p>
                                 </router-link>
                              </li>

                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="home-hero__banners home-banners">
                     <div class="home-banners__perfect perfect-home">
                        <span class="perfect-home__slogan">
                           Новинки сезона
                        </span>
                        <a :href="mainBanner.link" @click="validateBannerLink" v-if="Object.keys(mainBanner).length > 0"
                           class="perfect-home__card home-hero__banner"
                           :style="{ 'background': `url(${mainBanner.get_image}) center / 100% 100% no-repeat` }">
                           <div class="perfect-home__title">
                              {{ mainBanner.description }}
                           </div>
                        </a>
                        <!-- <router-link v-else to="/catalog/allProducts?tags=гриль"
                           class="perfect-home__card home-hero__banner">
                           <div class="perfect-home__title">
                              Идеально для гриля
                           </div>
                        </router-link> -->
                     </div>
                     <a :href="priceList.item.get_file" v-if="Object.keys(priceList).length > 0" download
                        class="home-banners__price home-hero__banner price-home">
                        <div class="price-home__header">
                           <div class="price-home__graphics">
                              <img src="@/assets/img/HomeView/hero-home-price.png" alt="">
                           </div>
                           <span class="price-home__download">
                              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"
                                 fill="none">
                                 <path d="M12 40H36M24 8V32M24 32L31 25M24 32L17 25" stroke="white" stroke-width="2.9"
                                    stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                           </span>
                        </div>
                        <div class="price-home__text">
                           <span v-if="Object.keys(priceList).length > 0">{{ priceList.item.name }}</span>
                           <span class="semi-bold">{{ priceList.message }}</span>
                        </div>
                     </a>
                     <a :href="this.youtube" class="home-banners__youtube home-hero__banner youtube-home">
                        <div class="youtube-home__header">
                           <a target="_blank" :href="this.youtube" class="youtube-home__logo">
                              <svg xmlns="http://www.w3.org/2000/svg" width="111" height="24" viewBox="0 0 111 24"
                                 fill="none">
                                 <path
                                    d="M33.608 3.74788C33.2125 2.27187 32.051 1.11195 30.5732 0.716838C27.8975 2.14576e-07 17.1628 0 17.1628 0C17.1628 0 6.4283 2.14576e-07 3.75243 0.716838C2.27463 1.11195 1.1133 2.27187 0.717709 3.74788C2.14837e-07 6.42049 0 12 0 12C0 12 2.14837e-07 17.5795 0.717709 20.2521C1.1133 21.7281 2.27463 22.888 3.75243 23.2831C6.4283 24 17.1628 24 17.1628 24C17.1628 24 27.8975 24 30.5732 23.2831C32.051 22.888 33.2125 21.7281 33.608 20.2521C34.3257 17.5795 34.3257 12 34.3257 12C34.3257 12 34.3229 6.42049 33.608 3.74788Z"
                                    fill="#FF0000" />
                                 <path d="M13.7285 17.1434L22.6462 12.0014L13.7285 6.85938V17.1434Z" fill="white" />
                                 <path
                                    d="M41.5739 15.6072L37.7197 1.70508H41.0822L42.4329 8.00703C42.7776 9.55924 43.0291 10.8829 43.193 11.9779H43.2918C43.4049 11.1933 43.6592 9.87815 44.052 8.02962L45.4506 1.70508H48.8131L44.9109 15.6072V22.2761H41.5711V15.6072H41.5739Z"
                                    fill="white" />
                                 <path
                                    d="M49.8252 21.8365C49.1471 21.3793 48.6639 20.6681 48.3757 19.7029C48.0903 18.7378 47.9463 17.4565 47.9463 15.8534V13.6718C47.9463 12.0547 48.1102 10.7538 48.4379 9.77445C48.7657 8.79515 49.2771 8.07831 49.9722 7.62958C50.6673 7.18086 51.58 6.95508 52.7103 6.95508C53.8236 6.95508 54.7136 7.18368 55.3861 7.64088C56.0558 8.09806 56.5474 8.81491 56.8582 9.78574C57.169 10.7594 57.3245 12.0547 57.3245 13.6718V15.8534C57.3245 17.4565 57.1719 18.7434 56.8696 19.7142C56.5672 20.6879 56.0756 21.3991 55.3974 21.8478C54.7193 22.2966 53.7981 22.5223 52.6368 22.5223C51.4387 22.5251 50.5035 22.2937 49.8252 21.8365ZM53.6286 19.4828C53.815 18.9918 53.9112 18.1931 53.9112 17.0812V12.3991C53.9112 11.321 53.8179 10.5308 53.6286 10.0341C53.4392 9.53457 53.1087 9.28622 52.6339 9.28622C52.1763 9.28622 51.8513 9.53457 51.6648 10.0341C51.4755 10.5336 51.3822 11.321 51.3822 12.3991V17.0812C51.3822 18.1931 51.4726 18.9946 51.6535 19.4828C51.8343 19.9739 52.1592 20.2194 52.6339 20.2194C53.1087 20.2194 53.4392 19.9739 53.6286 19.4828Z"
                                    fill="white" />
                                 <path
                                    d="M68.2625 22.28H65.6121L65.3182 20.4399H65.2448C64.5241 21.8284 63.4448 22.5226 62.0037 22.5226C61.0063 22.5226 60.2688 22.1952 59.7941 21.5434C59.3194 20.8886 59.082 19.867 59.082 18.4785V7.24891H62.47V18.2808C62.47 18.9526 62.5434 19.4295 62.6904 19.7146C62.8373 19.9996 63.0831 20.1435 63.4278 20.1435C63.7217 20.1435 64.0043 20.0532 64.2756 19.8726C64.5469 19.6919 64.7446 19.4633 64.8774 19.1868V7.24609H68.2625V22.28Z"
                                    fill="white" />
                                 <path d="M77.4653 4.42763H74.1028V22.278H70.7883V4.42763H67.4258V1.70703H77.4653V4.42763Z"
                                    fill="white" />
                                 <path
                                    d="M85.6365 22.28H82.9861L82.6922 20.4399H82.6188C81.8983 21.8284 80.8189 22.5226 79.3778 22.5226C78.3803 22.5226 77.6429 22.1952 77.1682 21.5434C76.6935 20.8886 76.4561 19.867 76.4561 18.4785V7.24891H79.844V18.2808C79.844 18.9526 79.9175 19.4295 80.0644 19.7146C80.2113 19.9996 80.4571 20.1435 80.802 20.1435C81.0957 20.1435 81.3783 20.0532 81.6496 19.8726C81.9209 19.6919 82.1186 19.4633 82.2514 19.1868V7.24609H85.6365V22.28Z"
                                    fill="white" />
                                 <path
                                    d="M96.8479 9.64896C96.6416 8.70071 96.3109 8.01491 95.8533 7.58876C95.3955 7.16261 94.7654 6.95095 93.9629 6.95095C93.3413 6.95095 92.7592 7.12592 92.2195 7.47869C91.6798 7.83147 91.2616 8.29149 90.9677 8.86439H90.9423V0.945312H87.6787V22.2755H90.4761L90.8208 20.8531H90.8943C91.157 21.3611 91.5498 21.759 92.0726 22.0554C92.5953 22.3489 93.1774 22.4957 93.816 22.4957C94.9604 22.4957 95.8052 21.9679 96.3449 20.9151C96.8846 19.8596 97.1558 18.2143 97.1558 15.9736V13.5944C97.1558 11.9152 97.0513 10.5972 96.8479 9.64896ZM93.7425 15.7816C93.7425 16.8765 93.6973 17.7345 93.6068 18.3554C93.5165 18.9763 93.3666 19.4195 93.1519 19.679C92.94 19.9415 92.6518 20.0713 92.2929 20.0713C92.0132 20.0713 91.7561 20.0064 91.5187 19.8738C91.2814 19.7439 91.0893 19.5464 90.9423 19.2867V10.7553C91.0553 10.346 91.2532 10.013 91.5329 9.75057C91.8098 9.4881 92.115 9.35828 92.4398 9.35828C92.7847 9.35828 93.0502 9.49374 93.2368 9.76186C93.426 10.0328 93.556 10.4843 93.6295 11.1222C93.7029 11.76 93.7397 12.6659 93.7397 13.8427V15.7816H93.7425Z"
                                    fill="white" />
                                 <path
                                    d="M101.963 16.6456C101.963 17.6108 101.991 18.3333 102.048 18.8159C102.104 19.2985 102.223 19.6485 102.404 19.8715C102.585 20.0915 102.862 20.2016 103.237 20.2016C103.743 20.2016 104.093 20.0041 104.28 19.6118C104.469 19.2195 104.571 18.5648 104.588 17.6504L107.51 17.8225C107.527 17.9523 107.535 18.1329 107.535 18.3615C107.535 19.75 107.154 20.7886 106.394 21.4744C105.634 22.1602 104.557 22.5045 103.167 22.5045C101.497 22.5045 100.327 21.9824 99.6572 20.9354C98.9848 19.8884 98.6514 18.2713 98.6514 16.0811V13.4565C98.6514 11.2016 98.999 9.55345 99.694 8.51489C100.389 7.47632 101.579 6.95703 103.266 6.95703C104.427 6.95703 105.32 7.1687 105.941 7.59485C106.563 8.021 107.001 8.68139 107.255 9.58168C107.51 10.482 107.637 11.7237 107.637 13.3097V15.8836H101.963V16.6456ZM102.392 9.56193C102.22 9.77358 102.107 10.1207 102.048 10.6033C101.991 11.0859 101.963 11.8169 101.963 12.799V13.8771H104.441V12.799C104.441 11.8338 104.407 11.1028 104.342 10.6033C104.277 10.1038 104.158 9.75383 103.986 9.54781C103.814 9.34462 103.548 9.24019 103.189 9.24019C102.828 9.24301 102.562 9.35026 102.392 9.56193Z"
                                    fill="white" />
                              </svg>
                           </a>
                           <a target="_blank" :href="this.youtube" class="youtube-home__arrow">
                              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48"
                                 fill="none">
                                 <path d="M12 38L38 12M38 12V36.96M38 12H13.04" stroke="white" stroke-width="3"
                                    stroke-linecap="round" stroke-linejoin="round" />
                              </svg>
                           </a>
                        </div>
                        <div class="youtube-home__content">
                           Просто и экспертно <br /> о рыбе и морепродуктах <br /> на нашем канале
                        </div>
                     </a>
                  </div>
               </div>
            </div>
         </section>
         <product-slider class="home-arrival__slider" :productsInfo="newArrivalProducts">
            Новое поступление
         </product-slider>

         <section class="home-assortment">
            <div class="container">
               <div class="home-assortment__card">
                  <div class="home-assortment__row" @mouseenter="categoryAutoPlay = false"
                     @mouseleave="categoryAutoPlay = true">
                     <div class="home-assortment__main">
                        <h2 class="home-assortment__title title">Ассортимент</h2>
                        <ul class="home-assortment__list">
                           <li v-for="(category, index) in this.assortmentCategories" :key="index" ref="category"
                              :class="{ active: index === 0 }">
                              <router-link :to="category.link" class="home-assortment__link"
                                 @mouseenter="showCategoryImage(index)">
                                 <span>{{ category.name }}</span>
                              </router-link>
                           </li>
                        </ul>
                     </div>
                     <div class="home-assortment__content">
                        <div ref="categoryImage" class="home-assortment__image" @mouseenter="showCategoryImage(index)"
                           v-for="(category, index) in this.assortmentCategories" :key="index"
                           :class="{ show: index === 0 }">
                           <router-link :to="category.link">
                              <img :src="category.get_image" alt="">
                           </router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <product-slider class="home-special__slider">
            Спецпредложения
         </product-slider>
         <section class="home-preferences">
            <div class="container">
               <div class="home-preferences__card">
                  <div class="home-preferences__row">
                     <div class="home-preferences__item">
                        <div class="home-preferences__image">
                           <img src="@/assets/img/icons/truck.svg" alt="">
                        </div>
                        <p class="home-preferences__text">Минимальная сумма заказа 500 тыс. рублей</p>
                     </div>
                     <div class="home-preferences__item">
                        <div class="home-preferences__image">
                           <img src="@/assets/img/icons/case.svg" alt="">
                        </div>
                        <p class="home-preferences__text">Работаем только с юридическими лицами</p>
                     </div>
                     <div class="home-preferences__item">
                        <div class="home-preferences__image">
                           <img src="@/assets/img/icons/donut.svg" alt="">
                        </div>
                        <p class="home-preferences__text">Необходимо наличие регистрации в системе Меркурий</p>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <product-slider class="home-popular__slider" :productsInfo="POPULAR_PRODUCTS">
            Популярные товары
         </product-slider>
         <news-section :news="news" />
      </main>
      <v-footer />
   </div>
</template>

<script>
import vHeader from "@/components/Header/v-header"
import vFooter from "@/components/Footer/v-footer"
import productSlider from "@/components/Sliders/product-slider"
import homeHeroSlider from "@/components/Sliders/home-hero-slider.vue"
import newsSection from "@/components/News/news-section"
import axios from "axios"
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex'
export default {
   name: 'HomeView',
   components: {
      vHeader,
      vFooter,
      homeHeroSlider,
      productSlider,
      newsSection
   },
   data() {
      return {
         priceList: {},
         isCategoryAutoPlay: false,
         youtube: "https://www.youtube.com/@moreodor/featured",
         heroSliderInfo: [
         ],
         heroNews: [],
         assortmentCategories: [
         ],
         news: {
            main: {},
            secondary: []
         },
         newArrivalProducts: [],
         mainBanner: {}
      }
   },
   computed: {
      ...mapGetters([
         "POPULAR_PRODUCTS"
      ]),
   },
   methods: {
      validateBannerLink(e) {
         if (!this.mainBanner.link.includes("https")) {
            e.preventDefault()
            this.$router.push(this.mainBanner.link)
         }
      },
      ...mapActions([
         "GET_POPULAR_PRODUCTS"
      ]),
      showCategoryImage(index) {
         this.i = index;
         this.$refs.category.forEach((item) => {
            item.classList.remove("active")
         })
         this.$refs.categoryImage.forEach((item) => {
            item.classList.remove("show")
         })
         this.$refs.category[this.i].classList.add("active")
         this.$refs.categoryImage[this.i].classList.add("show")
      },
      async GetFoNews() {
         await axios
            .get(`/api/v1/get/news/four/`)
            .then(response => {
               this.news.main = response.data.main
               this.news.secondary = response.data.secondary
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetLastNewsText() {
         await axios
            .get(`/api/v1/get/news/last/text/`)
            .then(response => {
               this.heroNews = response.data
            })
            .catch(error => {
               console.log(error)
            })
      },
      async getHeroBanners() {
         await axios("/api/v1/get/banners-slide/main/")
            .then(res => {
               this.heroSliderInfo = res.data
            })
            .catch(error => {
               console.log(error);
            })
      },
      async getPriceList() {
         await axios('/api/v1/get/price-list/info/')
            .then(res => {
               this.priceList = res.data
               // console.log(this.priceList);
            })
            .catch(error => {
               console.log(error);
            })
      },
      async getAssortiment() {
         await axios('/api/v1/get/catalog-slide/main/')
            .then(res => {
               this.assortmentCategories = res.data;
            })
            .catch(error => {
               console.log(error);
            })
      },
      async getNewArrivalProducts() {
         await axios("/api/v1/get/products/last-products/")
            .then(res => {
               this.newArrivalProducts = res.data;
            })
      },
      async getMainBanner() {
         await axios("/api/v1/get/suggestion-banner/main/")
            .then(res => {
               this.mainBanner = res.data
            })
      },
      CorDate(date) {
         return moment(String(date), "YYYY-MM-DD").format('DD.MM.YY')
      },
   },
   watch: {
      isCategoryAutoPlay(value) {
         if (!value) {
            clearInterval(this.timerId)
         } else {
            this.timerId = setInterval(() => {
               this.showCategoryImage(this.i);
               this.i++;
               if (this.i === this.$refs.category.length) {
                  this.i = 0;
               }
            }, 2000)
         }
      }
   },
   mounted() {
      this.i = 0;
      this.isCategoryAutoPlay = true;
   },
   beforeUnmount() {
      clearInterval(this.timerId)
   },
   created() {
      moment.locale('ru');
      this.GetFoNews()
      this.GetLastNewsText()
      this.getHeroBanners()
      this.getPriceList()
      this.getAssortiment()
      this.GET_POPULAR_PRODUCTS()
      this.getNewArrivalProducts()
      this.getMainBanner()
   },
}
</script>

<style lang="scss"></style>
