<template lang="">
   <section class="section-news">
      <div class="container">
         <div class="section-news__row">
            <div class="section-news__title title">Новости</div>
            <slot><more-btn to="news"/></slot>
         </div>
         <div class="section-news__grid">
            <router-link :to="'/article/' + news.main.slug" class="section-news__main main-article">
               <div class="main-article__image">
                  <img class="" :src="news.main.get_image" alt="">
               </div>
               <div class="main-article__content">
                  <div class="main-article__date">{{CorDate(news.main.date_added)}}</div>
                  <div class="main-article__title">{{news.main.title}}</div>
                  <p class="main-article__subtitle">{{news.main.subtitle}}</p>
               </div>
            </router-link>
            <router-link 
               v-for="(article, index) in news.secondary"
               :key="index"
               :to="'/article/' + article.slug"
               class="section-news__small secondary-article"
            >
               <div class="secondary-article__row">
                  <div class="secondary-article__image art_max-width">
                     <img :src="article.get_image" alt="">
                  </div>
                  <div class="secondary-article__content">
                     <div class="secondary-article__date">{{CorDate(article.date_added)}}</div>
                     <div class="secondary-article__title">{{article.title}}</div>
                     <div class="secondary-article__desc" v-html="article.small_disc">
                     </div>
                  </div>
               </div>
            </router-link>
         </div>
      </div>
   </section>
</template>
<script>
import moreBtn from "../Buttons/more-btn"
import moment from 'moment';
// import vLoading from "@/components/Loader/v-loader"
export default {
   name: "news-section",
   components: {
      moreBtn,
      // vLoading
   },
   props: {
      news: {
         type: Object,
         default() {
            return {}
         }
      }
   },
   data() {
      return {
         loading: true
      }
   },
   methods: {
      CorDate(date) {
         return moment(String(date), "YYYY-MM-DD").format('DD MMMM YYYY')
      },
   },
   mounted() {
      setTimeout(() => {
         this.loading = false
      }, 300)
   },
}
</script>
<style lang="scss">
.art_max-width {
   max-width: 240px;
}
</style>